<template>
  <vs-card class="h-full pl-1">
    <!-- Header -->
    <div slot="header" class="flex w-full">
      <h3>Homepage</h3>
      <vs-button class="ml-auto w-24" @click="save"> Save </vs-button>
    </div>

    <!-- Main Banner -->
    <h4 class="mb-4">Main Banner</h4>
    <vs-card class="pl-3">
      <label class="text-sm ml-1 mt-3">Desktop Image</label>

      <div v-if="data.main_banner.desktop_image_url">
        <img
          :src="data.main_banner.desktop_image_url"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('desktop-main-banner', data.main_banner)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          @change="
            handleFileChange($event, 'desktop-main-banner', data.main_banner)
          "
          accept="image/*"
        />
      </div>
      <label class="text-sm ml-1 mt-3">Mobile Image</label>

      <div v-if="data.main_banner.mobile_image_url">
        <img
          :src="data.main_banner.mobile_image_url"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('mobile-main-banner', data.main_banner)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          @change="
            handleFileChange($event, 'mobile-main-banner', data.main_banner)
          "
          accept="image/*"
        />
      </div>
      <vs-input
        label="Link"
        class="mt-3 md:w-1/2 w-full"
        v-model="data.main_banner.link"
      />
      <legend class="mt-2">The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
    </vs-card>
    <vs-divider />

    <!-- Popular Category -->
    <h4 class="mb-4">Popular Category</h4>

    <vs-card v-for="(item, key) in data.popular_category" :key="key">
      <vs-input label="Name" class="mt-3 md:w-1/2 w-full" v-model="item.name" />
      <vs-input
        label="Slug"
        class="mt-3 mb-3 md:w-1/2 w-full"
        v-model="item.slug"
      />
      <label class="text-sm ml-1"> Image</label>

      <div v-if="item.image">
        <img :src="item.image" alt class="img-fluid" />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('popular-category-img', item)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'popular-category-img', item)"
        />
      </div>
    </vs-card>
    <vs-divider />

    <!-- Promo -->
    <!-- Left -->

    <h4 class="mb-4">Promo Left</h4>
    <vs-card>
      <label class="text-sm ml-1"> Desktop</label>

      <div v-if="data.desktop_promo_left_image">
        <img
          :src="data.desktop_promo_left_image"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('promo-left-desktop-img', data)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'promo-left-desktop-img', data)"
        />
      </div>
      <label class="text-sm ml-1"> Mobile</label>

      <div v-if="data.mobile_promo_left_image">
        <img
          :src="data.mobile_promo_left_image"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('promo-left-mobile-img', data)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'promo-left-mobile-img', data)"
        />
      </div>
      <vs-input
        label="Link"
        class="mt-3 md:w-1/2 w-full"
        v-model="data.promo_left_url"
      />
      <legend class="mt-2">The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
    </vs-card>

    <!-- Promo -->
    <!-- Left -->
    <h4 class="mb-4">Promo Right</h4>
    <vs-card>
      <label class="text-sm ml-1"> Desktop</label>

      <div v-if="data.desktop_promo_right_image">
        <img
          :src="data.desktop_promo_right_image"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('promo-right-desktop-img', data)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'promo-right-desktop-img', data)"
        />
      </div>
      <label class="text-sm ml-1"> Mobile</label>

      <div v-if="data.mobile_promo_right_image">
        <img
          :src="data.mobile_promo_right_image"
          alt
          class="w-full h-auto max-w-md"
        />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('promo-right-mobile-img', data)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'promo-right-mobile-img', data)"
        />
      </div>
      <vs-input
        label="Link"
        class="mt-3 md:w-1/2 w-full"
        v-model="data.promo_right_url"
      />
      <legend class="mt-2">The square brackets <code>[ ]</code> in the URL, such as <code>[nurse]</code>, are placeholders that will be automatically replaced with the appropriate user type (e.g., <code>nurse</code>, <code>doctor</code>) when processed.</legend>
    </vs-card>

    <!-- Popular Products -->
    <h4 class="mb-4">Popular Products</h4>
    <vs-card>
      <div v-for="(product, key) in data.popular_product" :key="key">
        <vs-input
          :label="ordinal(key + 1) + ' product'"
          class="mt-3 md:w-1/2 w-full"
          v-model="data.popular_product[key]"
        />
      </div>
    </vs-card>
    <!-- Popular Brand -->
    <h4 class="mb-4">Popular Brands</h4>
    <vs-card v-for="(brand, key) in data.popular_brand" :key="key + brand.slug">
      <vs-input
        label="Slug"
        class="mt-3 mb-4 md:w-1/2 w-full"
        v-model="brand.slug"
      />
      <label class="text-sm ml-1"> Image</label>

      <div v-if="brand.image">
        <img :src="brand.image" alt class="fluid-img" />
        <br />

        <vs-button
          class="bg-primary2 my-2"
          @click="handleRemoveImage('popular-brand-image', brand)"
          >Remove Image</vs-button
        >
      </div>
      <div v-else>
        <input
          type="file"
          accept="image/*"
          @change="handleFileChange($event, 'popular-brand-image', brand)"
        />
      </div>
    </vs-card>

  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";

export default {
  data() {
    return {
      data: {},
    };
  },

  methods: {
    ...mapActions("eCommerce", ["saveJsonSettings", "getJsonSettings"]),
    save() {
      this.$vs.loading({
        text: "Saving...",
        color: "#3dc9b3",
        background: "white",
      });
      const slug = "homepage";
      const value = this.data;
      this.saveJsonSettings({ value, slug })
        .then((val) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Success",
            text: "Settings Saved.",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Failed to save settings.",
            text: "Please try again later.",
            color: "danger",
          });
          console.error(err);
        });
    },
    async handleRemoveImage(imgType, data) {
      if (imgType === "desktop-main-banner") data.desktop_image_url = "";
      if (imgType === "mobile-main-banner") data.mobile_image_url = "";
      if (imgType === "popular-category-img") data.image = "";
      if (imgType === "popular-brand-image") data.image = "";
      if (imgType === "promo-left-desktop-img")
        data.desktop_promo_left_image = "";
      if (imgType === "promo-left-mobile-img")
        data.mobile_promo_left_image = "";
      if (imgType === "promo-right-desktop-img")
        data.desktop_promo_right_image = "";
      if (imgType === "promo-right-mobile-img")
        data.mobile_promo_right_image = "";
    },
    uploadImage(event, imgType, card, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
      });
      let data = new FormData();
      data.append("profileImage", event);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (imgType === "desktop-main-banner")
            card.desktop_image_url = res.data.Location;
          if (imgType === "mobile-main-banner")
            card.mobile_image_url = res.data.Location;
          if (imgType === "popular-category-img")
            card.image = res.data.Location;
          if (imgType === "popular-brand-image") card.image = res.data.Location;
          if (imgType === "promo-left-desktop-img")
            card.desktop_promo_left_image = res.data.Location;
          if (imgType === "promo-left-mobile-img")
            card.mobile_promo_left_image = res.data.Location;
          if (imgType === "promo-right-desktop-img")
            card.desktop_promo_right_image = res.data.Location;
          if (imgType === "promo-right-mobile-img")
            card.mobile_promo_right_image = res.data.Location;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    ordinal(number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const v = number % 100;
      return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    },
    handleFileChange(event, imgType, card) {
      this.uploadImage(event.target.files[0], imgType, card);
    },
  },
  async created() {
    this.getJsonSettings({ slug: "homepage" }).then((val) => {
      this.data = val.data.data;
    });
  },
};
</script>